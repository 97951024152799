// @font-face {
//     font-family: Roboto;
//     src: url(../../assets/fonts/RobotoRegular.ttf) format("truetype");
// }
// @font-face {
//     font-family: RobotoBold;
//     src: url(../../assets/fonts/RobotoBold.ttf) format("truetype");
//     font-weight: bold;
// }
@font-face {
    font-family: FontAwesome5Free;
    src: url(../../assets/css/webfonts/fa-solid-900.ttf) format("truetype");
}

@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;600&display=swap');