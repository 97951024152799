﻿.pdf-page-controls {
    position: absolute;
    bottom: 5%;
    left: 50%;
    background: white;
    opacity: 0;
    transform: translateX(-20%);
    transition: opacity ease-in-out 0.2s;
    box-shadow: 0 30px 40px 0 rgba(16, 36, 94, 0.2);
    border-radius: 4px;
}


.react-pdf__Document:hover {
    .pdf-page-controls {
        opacity: 1;
    }
}

.switcher {
    input:checked + label:before {
        background: $theme;
        border: 2px solid $theme;
    }

    input:checked + label:after {
        left: 23px;
    }

    label:before {
        width: 43px;
        height: 17px;
    }

    label:after {
        width: 21px;
        height: 21px;
        top: -7%;
        left: 0px;
    }

    label {
        font-size: 12px;
    }
}

.permission-header {
    margin-bottom: 1px;
    font-size: 16px;
    font-weight: bold;
}

#annualCompliance {
    width: 100%;

    tr {
        th, td {
            padding: 8px;
            border-bottom: 1px solid #f2f2f2;
        }

        &:last-child {
            th, td {
                border-bottom: none;
            }
        }
    }
}
