@media (max-width: 575px) {
    ol.progtrckr {
        overflow-x: scroll;
        padding-bottom: 30px !important;
        margin-bottom: 0px !important;
    }
    .account-register-page {
        padding: 5px;
        .login-form-section {
            padding: 0px;
        }
        .container {
            width: 100%;
            .email-verification-btn {
                margin-top: 20px;
                margin-bottom: 20px;
            }
        }
    }

    .login-page {
        padding: 5px;
        #carouselExampleIndicators {
            display: none;
        }
    }
}

@media (min-width: 576px) and (max-width: 767px) {
    .login-page {
        #carouselExampleIndicators {
            display: none;
        }
    }
    ol.progtrckr {
        overflow-x: scroll;
        padding-bottom: 30px !important;
        margin-bottom: 0px !important;
    }
    .account-register-page {
        padding: 5px;
        .login-form-section {
            padding: 0px;
        }
        .container {
            width: 100%;
            .email-verification-btn {
                margin-top: 20px;
                margin-bottom: 20px;
            }
        }
    }
}

// Medium devices (tablets, 768px and up)
@media (min-width: 768px) and (max-width: 991px) {
    .login-page {
        .login-form-section {
            border-radius: 10px 10px 0px 0px;
        }
        #carouselExampleIndicators {
            .carousel-inner {
                border-radius: 0px 0px 10px 10px;
            }
        }
    }
    .account-register-page {
        .container {
            width: 100%;
        }
    }
}

// Large devices (desktops, 992px and up)
@media (min-width: 992px) and (max-width: 1199px) {
    .login-page {
        .login-form-section {
            border-radius: 10px 0px 0px 10px;
        }
        #carouselExampleIndicators {
            .carousel-inner {
                border-radius: 0px 10px 10px 0px;
            }
        }
    }
}

@media (max-width: 767px) {
    .breadcrumb {
        display :none;
    }
    
    .page-sidebar-toggled .sidebar-mobile-dismiss {
        display: block;
        position: fixed;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        z-index: 1020;
    }

    .form-footer {
        margin-left: 0;
        width: 100%;
    }

    .tabs {
        overflow-x: auto;
        overflow-y: hidden;
        display: flex;
        padding-bottom: 15px;
        flex-flow: nowrap;

        > div {
            width: auto;

            .tab-item {
                white-space: nowrap;
            }
        }
    }
}

// Extra large devices (large desktops, 1200px and up)
@media (min-width: 1200px) {
    .login-page {
        .login-form-section {
            border-radius: 10px 0px 0px 10px;
        }
        #carouselExampleIndicators {
            .carousel-inner {
                border-radius: 0px 10px 10px 0px;
            }
        }
    }
}
