﻿.react-tel-input {
    .form-control {
        border-top: 0 !important;
        border-left: 0 !important;
        border-right: 0 !important;
        border-radius: 0 !important;
        font-size: $input-font-size !important;
        padding-top: 0 !important;
        height: 25.5px !important;
        padding-left: 48px !important;
    }
    .flag-dropdown {
        border: none !important;
        background-color: transparent !important;
    }
    .selected-flag {
        .flag {
            margin-top: -6px !important;
        }
    }
}