.queryBuilder {
    padding: 10px;
    .ruleGroup {
        input,
        select {
            font-size: 14px !important;
        }
        // background: #f2efef;
        // padding: 5px;
        border-radius: 3px;
        &-header {
            text-align: end;
            border-bottom: 1px solid lightgray;
            line-height: 3rem;
            // padding: 10px 0px;
        }
        &-addGroup,
        &-addRule,
        &-combinators {
            margin-left: 5px;
            margin-right: 5px;
        }
        .rule {
            margin-top: 5px;
            margin-bottom: 5px;
        }
        .rule-value,
        .rule-operators,
        .rule-fields {
            margin-left: auto;
            margin-right: auto;
        }
    }
}

.empty-filter__wrapper {
    height: 200px;
}
