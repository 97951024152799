.sidebar {
    border-right: 1px solid rgba($light-gray, 0.6);
    background: $white;
    .nav {
        padding: 0px;
        >li {
            &.nav-header {
                @include h-padding(15px);
                font-size: 14px;
                padding-bottom: 12px;
            }
            >a {
                transition: all 250ms ease-out;
                border-radius: 0;
                padding: 10px 19px;
                color: $black;
                font-weight: 400;
                &:hover {
                    background-color: #f3f3f3;
                }
                >span {
                    font-size: 0.9rem;
                }
                >i {
                    font-size: 20px;
                }
            }
            &.active {
                >a {
                    box-shadow: inset 6px 0 0 0 $theme;
                    background-color: #f8f8f8;
                    color: $black;
                    font-weight: 600;
                }
            }
        }
    }
    .sub-menu {
        padding: 10px 0 10px 21px;
        background-color: darken($white, 4.5%);
        >li {
            >a:hover {
                color: $theme;
            }
            &.active>a {
                color: $theme !important;
            }
            a {
                color: #1f2225;
                font-weight: 400 !important;
            }
        }
    }
}

.sidebar-mobile-dismiss {
    display: none;
}

.float-sub-menu-container {
    .float-sub-menu {
        @include box-shadow(0px 0px 5px 0px rgba($gray, 0.75));
        li>a {
            font-weight: 400;
            &:hover {
                color: $theme;
            }
        }
    }
}

.page-sidebar-minified .sidebar .nav>li.active>a {
    color: $theme;
}

.sub-nav {
    @include box-shadow(0 0 1.25rem rgba(31, 45, 61, 0.08));
    background-color: $white;
    border-radius: 6px;
    ul {
        list-style: none;
        padding-left: 0;
        margin: 0;
        li {
            a {
                display: flex;
                justify-content: space-between;
                align-items: center;
                padding: 15px 19px;
                width: 100%;
                text-decoration: none;
                color: $black;
            }
            &:not(:last-child) {
                border-bottom: 1px solid #efefef;
            }
            &:hover {
                background: #fdfdfd;
                // border-radius: 15px;
                // box-shadow: 0px 2px 3px 0 rgba(0, 0, 0, .1);
            }
            &.spacer {
                padding: 3rem 0;
            }
            &.sub-nav-list {
                &.active {
                    a {
                        background: #e5e5e5;
                        font-weight: 600;
                    }
                }
            }
            .query__container {
                padding: 15px 19px;
            }
        }
    }
}

.sidebar-right {
    border-left: 1px solid #e0e0e0;
}

.sticky {
    width: 46px;
    position: fixed;
    top: 30%;
    z-index: 999999;
    background: $white;
    padding: 0.6rem 0 0.4rem 0.9rem;
    box-shadow: 0 0 50px 0 rgba(82, 63, 105, .15);
    i {
        font-size: 1.8rem
    }
    &.sticky-right {
        right: 0;
        border-radius: 1rem 0 0 1rem;
    }
    &.sticky-left {
        left: 0;
        border-radius: 0 1rem 1rem 0;
    }
}