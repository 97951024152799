$btn-size: 2.6rem;
$btn-size-sm: 1.8rem;
$border-radius: 0.5;
@mixin expand-anim($color, $background-color: $secondary-white) {
	&::after {
		@include box-shadow(inset 0 0 0 2px darken($background-color, 5%));
	}
	&:hover::after {
		@include box-shadow(inset 0 0 0 2px $color);
	}
	&::before {
		background: $color;
		box-shadow: inset 0 0 0 30px darken($background-color, 5%);
	}
	&:hover::before {
		box-shadow: inset 0 0 0 1px darken($background-color, 5%);
	}
}

@mixin btn-color($color, $text-color) {
	border-color: $color;
	background-color: $color;
	color: $text-color;
        border-radius: 10px;
	transition: all 0.3s;
	&:hover {
		background-color: darken($color, 6%);
		transition: all 0.3s;
	}
}

.btn-round {
	cursor: pointer;
	position: relative;
	display: inline-block;
	text-align: center;
	vertical-align: middle;
	width: $btn-size;
	height: $btn-size;
	border-radius: $btn-size * $border-radius;
	overflow: hidden;

	&::before,
	&::after {
		content: "";
		position: absolute;
		top: 0;
		left: 0;
		height: 100%;
		width: 100%;
		text-align: center;
		vertical-align: middle;
		transition: all 0.25s ease;
		border-radius: $btn-size * $border-radius;
	}

	i {
		position: relative;
		color: darken($gray, 30%);
		font-size: 1rem;
		margin-top: $btn-size / 3;
		transition: all 0.25s ease;
	}

	&:hover i {
		color: $white;
	}

	&.btn-sm {
		width: $btn-size-sm;
		height: $btn-size-sm;
		border-radius: $btn-size-sm * $border-radius;
		padding: 0;
		display: inline-flex;
		justify-content: center;
		align-items: center;

		i {
			font-size: 0.75rem;
			margin: 0;
			padding: 0;
		}
	}

	&.expand-theme {
		@include expand-anim($theme);
	}

	&.expand-green {
		@include expand-anim($green);
	}

	&.expand-red {
		@include expand-anim($red);
	}

	&.expand-blue {
		@include expand-anim($blue);
	}
}

.btn {
	font-size: 1rem;
	border-radius: 3px;
	&.btn-green {
		@include btn-color($green, $white);
	}
	&.btn-red {
		@include btn-color($red, $white);
	}
	&.btn-themed {
		@include btn-color($theme, $white);
	}
	&:disabled {
		cursor: not-allowed;
	}
	&:focus {
		box-shadow: none;
	}
}

.modal-themed {
        background-color: #EAEDED;
}

.btn-seperator {
	display: inline;
	margin-left: 15px;
	margin-right: 15px;
	border-right: 1px solid $light-gray;
	padding: 4px 0px 8px 0px;
}

.btn-min-width {
	min-width: 150px;
}

.btn-list {
	.btn {
		margin-right: 0.5rem;
	}
}

.btn-back-step {
	display: flex;
	justify-content: center;
	border: none;
}

button:focus {
	outline: none;
}

#toolbar {
	button {
		background: #fff;
		color: #222;
		border: 1px solid #e7e7e7;
		border-bottom: 2px solid #ddd;
		border-radius: 2px;
		padding: 4px 17px;
		margin-right: 3px;

		&.active {
			color: #fff;
			background: #008ffb;
			border: 1px solid blue;
			border-bottom: 2px solid blue;
		}
	}
}
