#notiticationDropdown {
    ul.notification-list {
        width: 340px;
    }
    .notification-menu-item {
        &.danger {
            border-left: 4px solid $red;
        }
        &.warning {
            border-left: 4px solid $red;
        }
        &.info {
            border-left: 4px solid $blue;
        }
        &.success {
            border-left: 4px solid $green;
        }
    }
    .notification-text {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        margin-bottom: 0;
    }
    i {
        margin: auto;
    }
}

.notificationView {
    .notification-view {
        &__container {
            a {
                color: $black;
                &:hover {
                    text-decoration: none;
                    color: $black;
                }
            }

            & .__header {
                padding: 10px;
            }

            .tabs {
                text-align: center;
                nav {
                    ul {
                        position: relative;
                        display: -ms-flexbox;
                        display: -webkit-flex;
                        display: -moz-flex;
                        display: -ms-flex;
                        display: flex;
                        margin: 0;
                        padding: 0;
                        max-width: 1200px;
                        list-style: none;
                        -ms-box-orient: horizontal;
                        -ms-box-pack: center;
                        -webkit-flex-flow: row wrap;
                        -moz-flex-flow: row wrap;
                        -ms-flex-flow: row wrap;
                        flex-flow: row wrap;
                        -webkit-justify-content: center;
                        -moz-justify-content: center;
                        -ms-justify-content: center;
                        justify-content: center;

                        li {
                            position: relative;
                            z-index: 1;
                            display: block;
                            margin: 0;
                            text-align: center;
                            -webkit-flex: 1;
                            -moz-flex: 1;
                            -ms-flex: 1;
                            flex: 1;
                            &.tab-current a {
                                color: #74777b;
                            }
                        }
                    }
                    a {
                        position: relative;
                        display: block;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        white-space: nowrap;
                        line-height: 2.5;
                        span {
                            vertical-align: middle;
                            font-size: 0.75em;
                        }
                        &:focus {
                            outline: none;
                        }
                    }
                }
            }

            .tabs-style-underline nav {
                background: #fff;
                a {
                    padding: 0.25em 0 0.5em;
                    -webkit-transition: color 0.2s;
                    transition: color 0.2s;
                    span {
                        font-weight: 700;
                    }
                }
                li a::after {
                    position: absolute;
                    bottom: 0;
                    left: 0;
                    width: 100%;
                    height: 6px;
                    background: #2cc185;
                    content: "";
                    -webkit-transition: -webkit-transform 0.3s;
                    transition: transform 0.3s;
                    -webkit-transform: translate3d(0, 150%, 0);
                    transform: translate3d(0, 150%, 0);
                }
                li.tab-current a::after {
                    -webkit-transform: translate3d(0, 0, 0);
                    transform: translate3d(0, 0, 0);
                }
            }
        }
    }
    .notification-table {
        tbody tr {
            &.unread {
                background-color: #f2f2f2;
            }
            border-bottom: 1px solid lightgrey;
            &:hover {
                background: #f2f3f4;
                cursor: pointer;
            }
        }
        tbody td {
            padding: 12px;
            .fa-envelope {
                color: gray;
            }
        }
    }
}
