.table-kyc-summary {
    th {
        padding-right: 1rem
    }
}

.listing-wrapper.kyc-listing {
    height: 86vh;
}

@media(max-width: $breakpoint-l) {
    .grid-kyc {
        grid-template-columns: 1fr;
    }
}

.sanction-detail {
    position: relative;
    margin-bottom: 1rem;
    &.disabled {
        max-height: 5rem;
        overflow-y: hidden;
        &::after {
            content: "";
            position: absolute;
            bottom: 0;
            height: 100%;
            width: 100%;
            z-index: 2;
            background: linear-gradient(0deg, #fff 15%, hsla(0, 0%, 100%, .13) 75%);
        }
    }
}

.sanction-wrapper {
    .badge {
        margin: 0 .1rem .1rem 0;
    }
}