.dropzone {
    display: flex;
    justify-content: center;
    align-items: center;
    .dropzone-img {
        width: 150px
    }
}

.non-elevated {
    .fileContainer {
        box-shadow: none;
    }
}

.deleteImage {
    width: 20px !important;
    height: 20px !important;
    font-size: 10px !important;
    line-height: 21px !important;
    background-color: #f70747 !important;
}