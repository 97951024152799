﻿@media print {

    @page {
        size: landscape
    }

    .pdf-viewer-section{
        display: none;
    }

    .KYC-Report-btn-section {
        display: none;
    }

    .KYC-report-btn {
        display: none;
    }

    .Confirmation-button {
        display: none;
    }

    .sidebar {
        display: none;
    }

    .printable-area{
        visibility: visible;
        min-width: 100%;
    }

    .content {
        margin: 0px;
        padding-top: 0px;
    }

    .header {
        display: none;
    }

    .nav-history-buttons {
        display: none;
    }

    .page-container {
        padding-top: unset;
    }

    .page-header {
        margin-bottom: 0px;
        display: none;
    }

    .original-nav-header {
        display: none;
    }

    .printable-nav-header {
        visibility:inherit !important;
        display: normal !important;
    }

    .icon-hiding {
        display: none !important;
    }

    .printed-button-p {
        display: none;
    }

    .printed-button {
        visibility: hidden;
    }

    .hidden-profile-span {
        display:inline-block !important;
    }

}