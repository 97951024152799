.servicesLayout {
    height: 80%;

    .right-nav {
        position: absolute;
        right: 0px;
        width: 13%;
        padding-left: 15px;
        /* z-index: 1050; */
        height: 85%;
        box-shadow: 0px 3px 5px 0px rgba(166, 166, 166, 0.75);
        border-left: 1px solid #e5e5e5;
        ol {
            padding-left: 5px;
            li {
                font-size: 14px;
            }
        }
    }

    & .__jumbotron {
        background: transparent;
    }
    .card-header {
        background: transparent;
        font-weight: bold;
    }
    .card-body {
    }
    small {
        font-style: italic;
    }
    .icon__wrapper {
        color: $theme;
        position: absolute;
        top: 15px;
        right: 15px;
        font-size: 36px;
    }
    .allotment__container {
        height: 100%;
        // & > div:not(:first-child) {
        //     border-left: 1px solid #e5e5e5;
        // }
    }

    .allotment-table {
        thead,
        tbody {
            tr {
                border-bottom: 1px solid #e5e5e5;
            }
            td {
                padding: 8px;
                .transferee-list-wrapper {
                    padding: 5px;
                    max-height: 250px;
                    overflow-y: auto;
                    margin-bottom: 20px;
                    border: 1px solid lightgray;
                    border-radius: 3px;
                }
            }
        }
    }

    .newly-allotment-table {
        tbody tr td.allotshare__input {
            width: 33.33%;
        }
    }
}

.serviceItems {
    border-bottom: 1px solid #f2f2f2;
}
