.vertical-stepper__container {
    height: 100%;
    display: flex;

    form {
        height: 100%;
    }
    
    .vertical-stepper__steps {
        flex: 1;
        height: 100%;
        border-right: 1px solid $light-gray;
    }
    
    .vertical-stepper__item {
        padding: 1rem 1.3rem;
        // background: $secondary-white;
        display: flex;
        transition: background 0.2s;
        cursor: pointer;

        div {
            flex: 1;
        }

        .vertical-stepper__progress {
            p {
                text-align: right;
            }
        }

        p {
            margin-bottom: 0;
        }

        &:not(:last-child) {
            border-bottom: 1px solid $light-gray;
        }

        &:hover {
            background: darken($secondary-white, 5%);
            transition: background 0.2s;
        }

        &.active {
            background: $theme;
            color: $white;

            p {
                color: $white !important;
            }
        }
    }

    .vertical-stepper__content {
        position: relative;
        flex: 3;
        height: 100%;
        
        .vertical-stepper__content__container {
            height: 88%;
            overflow-y: auto;
            padding: 10px 20px 10px;
        }

        .form-footer {
            all: unset;
            border-top: 1px solid $light-gray;
            background: $white;
            
            button {
                margin-left: 20px;

                &:last-child {
                    margin-right: 20px;
                }
            }
        }
    }
}