// #addIncorpStepProgress {
$stepper-indicator-size: 1.5em;
ol {
    &.progtrckr {
        display: flex;
        justify-content: center;
        list-style-type: none;
        padding: 0;
        margin-bottom: 3rem;
        li {
            width: 200px;
            display: inline-block;
            text-align: center;
            cursor: pointer;
            span {
                pointer-events: none;
                display: inline-block;
                font-family: $font-bold;
                font-size: 1rem;
                padding: 0 1.5rem;
                &:before {
                    content: "";
                    color: $white;
                    position: absolute;
                    bottom: -14px;
                    left: calc(50% - 1.5em / 2);
                    width: $stepper-indicator-size;
                    height: $stepper-indicator-size;
                    line-height: $stepper-indicator-size - 0.2;
                    font-size: 0.8rem;
                }
            }
            &.progtrckr-todo,
            &.progtrckr-doing,
            &.progtrckr-done {
                position: relative;
                padding-bottom: 20px;
            }
            &.progtrckr-todo {
                color: $light-gray;
            }
            &.progtrckr-doing {
                color: $black;
                &:before {
                    border-color: $theme;
                    background-color: $theme;
                }
                span:before {
                    font-family: "FontAwesome5Free";
                    content: "\f128";
                }
            }
            &.progtrckr-done {
                color: $green;
                &:before {
                    border-color: $green;
                    background-color: #00cc66;
                }
                span:before {
                    font-family: "FontAwesome5Free";
                    content: "\f00c";
                }
                &:not(:last-child):after {
                    background-color: $green !important;
                }
            }
            &:before {
                content: "";
                color: white;
                background-color: lighten($light-gray, 5%);
                width: $stepper-indicator-size;
                height: $stepper-indicator-size;
                border: 3px solid $light-gray;
                border-radius: $stepper-indicator-size * 0.5;
                position: absolute;
                bottom: -14px;
                left: calc(50% - 1.5em / 2);
            }
            &:after {
                content: "";
                position: absolute;
                bottom: -4px;
                width: 100%;
                height: 3px;
                background-color: $white;
            }
            &:not(:last-child):after {
                background-color: $light-gray;
                left: calc(50% + (1.5em / 2));
            }
            &:last-child:after {
                width: calc(50% - (1.5em / 2));
                left: calc(50% + (1.5em / 2));
            }
        }
        em {
            pointer-events: none;
            // display: inline-block;
            display: none;
            font-family: $font-bold;
            font-size: 1.2rem;
            padding-left: 0.5rem;
        }
    }
}

@media (max-width: 650px) {
    .progtrckr li span {
        display: none;
    }
    .progtrckr em {
        display: inline;
    }
}

// }
.no-shareholders {
    text-align: center;
    font-style: italic;
    letter-spacing: 1px;
    font-size: 18px;
}

.shareholder-selection {
    display: flex;
    justify-content: space-evenly;
    .shareholder-selection-item {
        flex: 1;
        text-align: center;
        font-size: 2rem;
        border: 1px solid #ccc;
        border-radius: 10px;
        padding: 2rem 1rem;
        cursor: pointer;
        background-color: $white;
        color: $black;
        transition: all 0.2s;
        i {
            margin-bottom: 20px;
        }
        &:not(:last-child) {
            margin-right: 1rem;
        }
        &:hover {
            background-color: $theme;
            border-color: $theme;
            color: $white;
            transition: all 0.2s;
        }
    }
}

.position-selection {
    display: flex;
    flex-direction: column;
    align-items: center;
    .position-selection-item {
        width: 400px;
        text-align: center;
        margin-bottom: 10px;
        border: 1px solid #ccc;
        border-radius: 20px;
        padding: 1rem 0.5rem;
        cursor: pointer;
        background-color: $white;
        color: $black;
        font-family: $font-bold;
        transition: all 0.2s;
        &:hover,
        &.active {
            background-color: $theme;
            border-color: $theme;
            color: $white;
            transition: all 0.2s;
        }
        &.active {
            &:hover {
                background-color: darken($theme, 5%);
                transition: all 0.2s;
            }
        }
    }
}

.btn-add-shareholder {
    position: absolute;
    left: calc(50% - (111.95px / 2));
    bottom: calc(50% - (52px / 2));
}

#treeWrapper {
    width: 100%;
    height: 100%;
}

.node-label-container {
    display: flex;
    .node-label-icon {
        flex: 1;
        i {
            @include v-padding(5px);
            font-size: 2.2rem;
            background-color: $white;
        }
    }
    .node-label-content {
        overflow: hidden;
        flex: 80%;
        padding-left: 10px;
        height: 100%;
        h3 {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            margin-bottom: 0;
        }
        .node-label-position {
            font-size: 1.15rem;
            font-style: italic;
        }
        .node-label-units {
            font-size: 1rem;
        }
        .node-btn-group {
            padding: 5px 0px 5px;
            button {
                &:not(:last-child) {
                    margin-right: 5px;
                }
            }
        }
    }
}

#shareCapitalForm {
    width: 750px;
    label {
        margin-bottom: 0;
    }
}

.modal {
    .custom-sized {
        max-width: unset;
        margin: 1.75rem 10%;
    }
    .custom-modal .modal-content {
        >.modal-header {
            border-bottom: none;
            h5 {
                font-size: 1.2rem;
            }
        }
        .justify-between {
            justify-content: space-between;
        }
    }
}

#addIncorpStepProgress .multi-step {
    height: 87%;
}

.officer-item {
    display: flex;
    flex-direction: row;
    padding: 20px 10px;
    .officer-item__details {
        flex: 2;
    }
    .officer-item__action {
        // flex: 1;
        display: flex;
        padding-left: 20px;
        justify-content: center;
        align-items: center;
    }
}

#sharesForm {
    $gr-1: linear-gradient(170deg, #f6eda0 0%, #feffda 100%);
    $gr-2: linear-gradient(170deg, #b4ec51 0%, #429321 100%);
    $gr-3: linear-gradient(170deg, #c86dd7 0%, #3023ae 100%);
    .gr-1 {
        background: $gr-1;
    }
    .gr-2 {
        background: $gr-2;
    }
    .gr-3 {
        background: $gr-3;
    }
    * {
        transition: 0.5s;
    }
    .align-middle {
        position: relative;
        top: 50%;
        // transform: translateY(-50%);
    }
    .column {
        margin-top: 3rem;
        padding-left: 3.5rem;
        &.active {
            padding-left: 0;
            .card {
                border: 2px solid $theme;
                .txt {
                    margin-left: 1rem;
                    h1,
                    p {
                        color: #666666;
                        opacity: 1;
                    }
                }
                a {
                    color: #666666;
                    &:after {
                        width: 10%;
                    }
                }
            }
        }
        &:hover {
            .card {
                border: 2px solid $theme;
            }
        }
    }
    .card {
        border: 2px solid rgba(0, 0, 0, 0.27);
        &:hover {
            border: 2px solid $theme;
        }
        height: 100%;
        min-height: 170px;
        margin: 0;
        padding: 1.7rem 1.2rem;
        border-radius: 7px;
        color: rgba(0, 0, 0, 1);
        letter-spacing: 0.05rem;
        font-family: "Oswald",
        sans-serif;
        box-shadow: 0 0 21px rgba(0, 0, 0, 0.27);
        .txt {
            margin-left: -3rem;
            z-index: 1;
            h1 {
                font-size: 1.5rem;
                font-weight: 300;
                text-transform: uppercase;
            }
            p {
                font-size: 0.7rem;
                font-family: "Open Sans", sans-serif;
                letter-spacing: 0rem;
                margin-top: 33px;
                opacity: 0;
                color: rgba(255, 255, 255, 1);
            }
        }
        a {
            z-index: 3;
            font-size: 0.7rem;
            color: rgba(0, 0, 0, 1);
            margin-left: 1rem;
            position: relative;
            bottom: -0.5rem;
            text-transform: uppercase;
            &:after {
                content: "";
                display: inline-block;
                height: 0.5em;
                width: 0;
                margin-right: -100%;
                margin-left: 10px;
                border-top: 1px solid rgba(255, 255, 255, 1);
                transition: 0.5s;
            }
        }
        .check-icon {
            position: absolute;
            top: 10px;
            right: 10px;
            color: $green;
            font-size: 20px;
        }
        .ico-card {
            position: absolute;
            top: 0;
            left: 0;
            bottom: 0;
            right: 0;
            width: 100%;
            height: 100%;
            overflow: hidden;
            i {
                position: relative;
                right: -50%;
                top: 60%;
                font-size: 12rem;
                line-height: 0;
                opacity: 0.2;
                color: grey;
                z-index: 0;
            }
        }
    }
}

.incorporation-summary-view__container {
    $text-grey: #999999;
    $border-color-1: #2d3b41;
    p {
        font-size: 13px;
    }
    & .__text-style-1 {
        color: $text-grey;
        font-size: 12px;
    }
    & .__text-style-2 {
        font-weight: bold;
    }
    & .__status-text {
        font-size: 14px;
        font-weight: bold;
        &.live {
            color: $green;
        }
        &.deregistering {
            color: $theme;
        }
        &.fail {
            color: $red;
        }
    }
    & .__header {
        // border-top: 1px solid lightgrey;
        border-bottom: 1px solid lightgrey;
        padding: 3px;
        p {
            margin-top: auto;
            margin-bottom: auto;
        }
    }
    & .__body-content {
        &>div {
            height: fit-content;
        }
        .card {
            margin-bottom: 6px;
        }
        .content-head {
            hr {
                height: 3px;
                border-radius: 3px;
                background: $theme;
            }
        }
        & .content-field {
            label {
                font-size: 14px;
                margin-bottom: 3px;
                font-weight: lighter;
                text-transform: uppercase;
                color: #7f7f7f;
                // font-weight: bold;
            }
            .sub-label {
                font-weight: lighter;
                margin-left: 15px;
                color: $black;
                font-size: 13px;
                margin-top: auto;
                margin-bottom: auto;
            }
            p {
                margin-top: auto;
                margin-bottom: auto;
                font-weight: bold;
                margin-left: 10px;
                font-style: italic;
            }
            & .__wrapper {
                display: grid;
                &>div>div {
                    // display: flex;
                    margin-bottom: 10px;
                }
            }
        }
        & .officers-table {
            width: 100%;
            text-align: center;
            .view-btn {
                padding: 2px 5px;
            }
            th {
                font-size: 13px;
                text-align: center;
            }
            tbody {
                font-size: 0.75rem;
            }
        }
    }
    .incorporation-sum-view__item {
        min-height: 300px;
        margin-bottom: 20px;
        &.company-info {
            .card {
                min-height: 168px;
            }
        }
        &.company-officer {
            .card {
                min-height: 350px;
            }
            .card-title {
                border-bottom: 1px solid rgba(0, 0, 0, 0.125);
            }
            .company-officer__item {
                padding-top: 10px;
                border-bottom: 1px solid rgba(0, 0, 0, 0.125);
            }
        }
        .scroller-container__wrapper {
            padding-top: 0px;
            max-height: 300px;
            overflow: auto;
        }
        .sub-container__item {
            padding: 5px;
            border-radius: 7px;
            .card-title {
                font-style: italic;
            }
            .card {
                height: 100%;
                background: #f2f3f4;
            }
        }
        .sub-container-details__wrapper {
            display: flex;
            justify-content: space-between;
            &:not(:last-child) {
                margin-bottom: 20px;
            }
        }
        p {
            font-size: 14px;
        }
    }
}

.company-officer-detail {
    &__container {
        // hr {
        //     margin-top: 5px;
        //     margin-bottom: 5px;
        //     height: 3px;
        //     border-radius: 3px;
        //     background: $theme;
        // }
        & .right__panel {
            padding-top: 0px !important;
            overflow-y: auto;
            .header__section {
                position: sticky;
                top: 0;
                background: $white;
            }
            table {
                thead {
                    &>th {
                        text-align: center;
                    }
                }
            }
        }
    }
    &__wrapper {
        &>div {
            display: flex;
            label {
                font-size: 14px;
                font-weight: bold;
            }
            p {
                font-size: 14px;
            }
        }
    }
}

.portfolio-services {
    &__container {
        table tbody tr:hover {
            background: #f2f3f4;
        }
    }
}

.table-statuslist {
    td {
        text-align: left;
        padding: 5px;
    }
    .status-icon {
        font-size: 100%;
    }
}

.status-icon {
    font-size: 100%;
    width: 15px;
    height: 15px;
    border-radius: 50%;

    &.incomplete {
        background-image: unset !important;
    }

    &.status-grey {
        background-image: linear-gradient(90deg, #b9b9b9, #cecece);

        &.incomplete {
            border: 2px solid #b9b9b9;
        }
    }

    &.status-darkgrey {
        background-image: linear-gradient(90deg, #2d3b41, #465c66);

        &.incomplete {
            border: 2px solid #2d3b41;
        }
    }

    &.status-red {
        background-image: linear-gradient(90deg, #ff4b4b, #ff7777);

        &.incomplete {
            border: 2px solid #ff4b4b;
        }
    }

    &.status-orange {
        background-image: linear-gradient(90deg, #ff7c25, #ff9946);

        &.incomplete {
            border: 2px solid #ff7c25;
        }
    }

    &.status-blue {
        background-image: linear-gradient(90deg, #2f33ff, #4e51fa);

        &.incomplete {
            border: 2px solid #2f33ff;
        }
    }

    &.status-light-blue {
        background-image: linear-gradient(90deg, #e8f4f8, #72bcd4);

        &.incomplete {
            border: 2px solid #c1e1ec;
        }
    }

    &.status-purple {
        background-image: linear-gradient(90deg, #c683c9, #57006b);

        &.incomplete {
            border: 2px solid #b622c9;
        }
    }

    &.status-gold {
        background-image: linear-gradient(90deg, #FFD700, #996515);

        &.incomplete {
            border: 2px solid #FFD700;
        }
    }

    &.status-green {
        background-image: linear-gradient(90deg, #80ff80, #008000);

        &.incomplete {
            border: 2px solid #00e600;
        }
    }
}

.green-check {
    color: $green
}

.ul-icons {
    li {
        padding-left: 1.2rem;
        position: relative;
        i {
            position: absolute;
            left: 0;
            top: 10%;
        }
    }
}

.doc-listing-wrapper {
    display: flex;
    flex-direction: column;
    &.page-height {
        height: 80vh;
    }
    .doc-listing {
        display: flex;
        flex-direction: column;
        flex: 1;
        .listing-wrapper {
            flex: 1;
            max-height: 35vh
        }
    }
}

.nowrap.MuiButtonBase-root .MuiTab-wrapper {
    white-space: nowrap;
}