#receiptsModalPdfViewer {
    .modal-content {
        min-width: 360px;
        width: fit-content;
    }
}

#OrderSummary {
    // padding: 15px;
    // border: 1px solid rgba(31, 34, 37, 0.15);
    // border-radius: 13px;
    // margin-bottom: 40px;
    .payment-method__formgroup {
        width: 80%;
        .radio.radio-css input:checked+label:before {
            translate: translateY(50%);
        }
        // .radio.radio-css input:checked + label:after {
        //     translate: translateY(115%);
        // }
    }
    .cart-item-wrapper {
        &.active {
            border: 2px solid $green !important;
        }
        &:hover {
            border: 1px solid $theme;
        }
        .check-icon {
            color: $green;
        }
        white-space: nowrap;
        cursor: pointer;
    }
    .cart-item-summaries-wrapper {
        padding: 10px;
        max-height: 60vh;
        overflow-y: auto;
        // box-shadow: 4px 4px 10px rgba(31,34,37,.15);
        margin-bottom: 10px;
    }
    .total-amount-wrapper {
        padding: 5px 0px 5px 0px;
        border-top: 1px solid rgba(31, 34, 37, 0.15);
        border-bottom: 1px solid rgba(31, 34, 37, 0.15);
    }
    img.payment-logo {
        width: 250px;
    }
    .payment-method-wrapper {
        min-height: 30vh;
        text-align: center;
        margin-top: 20px;
    }
}

.item-row {
    margin-bottom: .5rem;
    .card-body {
        display: grid;
        grid-gap: 1rem;
        grid-template-columns: auto auto auto auto 1fr auto auto;
        .chk-item {
            text-align: center;
        }
        .check-icon {
            color: #1ec51b;
            transition: all 250ms cubic-bezier(0, 1.14, 0.58, 1);
            transform: scale(0);
        }
    }
    &.active {
        .card {
            box-shadow: 0 0 1.25rem rgba(31, 45, 61, 0.08), inset 0 0 0px 2px #1ec51b;
        }
        .check-icon {
            transform: scale(1);
        }
    }
    &:hover {
        cursor: pointer;
    }
}

.table-cart {
    tr {
        td:last-child {
            font-weight: bold;
        }
    }
}

.cart-footer {
    padding: 0 15px 3rem 15px;
    display: grid;
    grid-template-columns: auto .1fr;
    strong:last-of-type {
        display: flex;
        justify-content: flex-end;
    }
}

.pmt-method-wrapper {
    display: flex;
    .pmt-method-selection {
        flex: 1;
        position: relative;
        &:not(:last-child) {
            margin-right: .5rem
        }
        &.active {
            box-shadow: 0 0 1.25rem rgba(31, 45, 61, 0.08), inset 0 0 0px 2px #1ec51b;
            .check-icon {
                color: #1ec51b;
                transform: scale(1);
            }
        }
        .check-icon {
            position: absolute;
            top: 1rem;
            left: 1rem;
            transition: all 250ms cubic-bezier(0, 1.14, 0.58, 1);
            transform: scale(0);
        }
        .card-body {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: space-around;
            img {
                width: 100%;
                max-width: 200px;
                margin-bottom: .5rem
            }
        }
    }
}

.cart-header {
    font-weight: bold;
    display: grid;
    grid-gap: 1rem;
    padding: 1rem;
    grid-template-columns: auto auto auto auto 1fr auto auto;
}

.table-cart {
    tbody {
        td,
        th {
            &:first-child {
                input[type='checkbox'] {
                    margin-top: .1rem;
                }
            }
        }
    }
    td,
    th {
        padding: .5rem;
        border-color: #f2f2f2 !important;
    }
    thead {
        th {
            padding: 1rem .5rem;
        }
    }
}

.addon-list {
    li:not(:last-child) {
        margin-bottom: .5rem
    }
    li {
        align-items: flex-start;
    }
}

.cart-header {
    color: black;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    width: 100%;
    display: inline-block;
    font-size: 12px;
    padding-top: inherit;
    padding-left: initial;
}

.cart-subheading {
    margin-left: 1px;
    font-weight: normal;
    font-size: smaller;
    color: #8a8a8a;
    padding-top: inherit;
}

.cart-header-border {
    border-bottom-color: #ebebeb;
    border-bottom-width: thin;
    border-bottom-style: double;
}
