.EditAccountModal,
.SubAccount {
    margin: auto;
    .react-tel-input {
        height: 43px;
        input {
            height: inherit;
        }
    }
    &.account-listing {
        .status {
            padding: 3px 5px 3px 5px;
            width: 100%;
            border-radius: 50px;
            font-weight: bold;
            text-transform: capitalize;
            text-align: center;
            &.account-active {
                background: $green;
            }
            &.account-inactive {
                background: $red;
            }
        }
    }
    #createSubAccountBtn {
        margin-left: auto;
    }
    &.set-password {
        height: 100vh;
        .setpassword-wrapper {
            padding-left: 20%;
            padding-right: 20%;
            margin: auto;
            height: 100%;
            background: darken($white, 1%);
            // background: rgb(244, 226, 156);
            // background: -moz-linear-gradient(-45deg, rgba(244, 226, 156, 0) 0%, rgba(59, 41, 58, 1) 100%),
            //     -moz-linear-gradient(left, rgba(244, 226, 156, 1) 0%, rgba(130, 96, 87, 1) 100%);
            // background: -webkit-linear-gradient(-45deg, rgba(244, 226, 156, 0) 0%, rgba(59, 41, 58, 1) 100%),
            //     -webkit-linear-gradient(left, rgba(244, 226, 156, 1) 0%, rgba(130, 96, 87, 1) 100%);
            // background: -o-linear-gradient(-45deg, rgba(244, 226, 156, 0) 0%, rgba(59, 41, 58, 1) 100%),
            //     -o-linear-gradient(left, rgba(244, 226, 156, 1) 0%, rgba(130, 96, 87, 1) 100%);
            // background: -ms-linear-gradient(-45deg, rgba(244, 226, 156, 0) 0%, rgba(59, 41, 58, 1) 100%),
            //     -ms-linear-gradient(left, rgba(244, 226, 156, 1) 0%, rgba(130, 96, 87, 1) 100%);
            // background: linear-gradient(135deg, rgba(244, 226, 156, 0) 0%, rgba(59, 41, 58, 1) 100%),
            //     linear-gradient(to right, rgba(244, 226, 156, 1) 0%, rgba(130, 96, 87, 1) 100%);
        }
        #subAccountSetPasswordForm {
            margin: auto;
            width: 80%;
        }
        .img-wrapper {
            text-align: center;
            color: $black;
            margin: auto;
            img {
                margin: auto;
            }
        }
    }
}
