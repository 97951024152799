.ProfileManagement {
    .brief-bio-field {
        width: 100%;
        border: none;
        &:active {
            outline: cadetblue;
        }
    }
    label {
        margin: auto;
    }
    input {
        font-size: 16px;
    }
    .profile-head-font {
        font-size: 36px;
        font-weight: bold;
    }
    .change-pw-footer {
        margin-left: auto;
    }
}

.ProfileManager {
    .accountant-info-wrapper {
        .avatar-container {
            width: fit-content;
            height: fit-content;
        }
        padding: 10px;
        width: 80%;
    }

    .avatar {
        border-radius: 50%;
        margin: 10px;
        border: 5px solid;
        border-color: -webkit-gradient(
            linear,
            left top,
            left bottom,
            from(#00abeb),
            to(#fff),
            color-stop(0.5, #fff),
            color-stop(0.5, #66cc00)
        );
    }
    .profile-name {
        font-size: 24px;
        font-weight: bold;
        font-style: italic;
    }
    .tags {
        background: #fff;
        padding: 5px;
        overflow: hidden;
    }

    .tag {
        list-style: none;
        color: #fff;
        font-weight: bold;
        background: #e6ac00;
        float: left;
        padding: 5px 10px;
        border-radius: 10em;
        margin: 5px;
    }

    .ProfileEditor {
        .avatar {
            margin: auto;
            font-family: "Lato", sans-serif;
            width: 250px;
            height: 250px;
            display: block;
            border-radius: 50%;
            overflow: hidden;
            position: relative;
            transition: border 0.15s ease-in;
            &:hover {
                border: 2px solid #0aaff1;
                cursor: pointer;
                .edit {
                    padding-top: 10px;
                    height: 40px;
                }
            }
            .avatar-img,
            .edit {
                position: absolute;
                width: 100%;
                height: 100%;
            }
            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
            .edit {
                width: 100%;
                height: 0px;
                z-index: 1;
                bottom: 0;
                font-weight: bold;
                color: white;
                background-color: rgba(10, 175, 241, 0.781);
                transition: all 1s;
                padding-top: 0px;
                text-align: center;
            }
        }
    }
}

.btn-circle.btn-xl {
    width: 40px;
    height: 40px;
    text-align: center;
    border-radius: 35px;
    font-size: 24px;
    line-height: 1.33;
}

.text-operation-status {
    font-weight: bold;
    span {
        border-radius: 50px;
        padding: 5px 10px 5px 10px;
    }
    &.success {
        span {
            background: $green;
        }
    }
    &.fail {
        span {
            background: $red;
        }
    }
}
