.tooltip-inner {
    padding: .5rem;
    background: #fff;
    color: #000;
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, .1);
    font-size: 1rem;
}

.tooltip-qcdoc {
    padding: 0.35rem !important;
    background: #525252;
    color: #ffffff;
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, .1);
    font-size: 0.75rem;
}

.tooltip-adminbulbs {
    padding: 0.1rem;
    background: #8c8c8c;
    //color: #ffffff;
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, .1);
    font-size: 1.5rem;
    font-weight: bold;
    border: 2px solid black;
    border-radius: 10px;
}

.arrow::before {
    display: none
}