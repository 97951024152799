﻿// 1. Colors
$black: #202324;
$white: #FFF;
$secondary-white: #f2f3f4;
$light-gray: #CCC;
$gray: #a6a6a6;
$theme: #ff0066;
$green: #00cc66;
$red: #f64e60;
$orange: #ffaa00;
$blue: #0099ff;
$pink: #ff6699;
$yellow: #e1ff00;
$faded-purple: #e88ee5;
// 2. Fonts
$font: "Poppins",
sans serif;
$font-bold: "Poppins",
sans serif;
$font-awesome: "FontAwesome5Free";
$input-font-size: 16px;
$breakpoint-l: 425px;
$lighter-gray: #CCC;