.card-task {
    display: flex;
    align-items: flex-start;
    flex-direction: row;
    .left-panel {
        flex: 1;
        line-height: 1.2;
        .title {
            font-weight: 800;
        }
    }
    .timestamp {
        font-size: 90%;
        color: #424242 !important;
        margin-bottom: 0.2rem;
    }
    .badge {
        text-overflow: ellipsis;
        overflow-x: hidden;
        max-width: 180px;
    }
    .right-panel {
        text-align: right
    }
}

.dashboard-col-wrapper {
    margin-bottom: 1rem;
}

.col-group-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: .5rem;
    .header-title {
        font-size: 1.1rem;
        font-weight: bold;
    }
    a {
        font-weight: bold;
        white-space: nowrap;
    }
}