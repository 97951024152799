@mixin box-shadow ($value) {
    -webkit-box-shadow: $value;
    -moz-box-shadow: $value;
    box-shadow: $value;
}

@mixin h-padding($value) {
    padding-left: $value;
    padding-right: $value;
}

@mixin v-padding($value) {
    padding-top: $value;
    padding-bottom: $value;
}

@mixin material-ui-label($active: false) {
    @if $active {
        transform: translate(0, -120%) !important;
        font-size: 80% !important;
        padding-left: 0 !important;
        opacity: 1;
    }
    @else {
        transition: all 200ms cubic-bezier(0.0, 0, 0.2, 1);
        font-size: 1rem !important;
        position: absolute;
        top: 16px !important;
        pointer-events: none;
        transform: none !important;
        opacity: .6;
    }
}

@mixin material-ui-input {
    border-top: 0;
    border-right: 0;
    border-left: 0;
    border-bottom: 2px solid #dfe1e4;
    border-radius: 0;
    padding-left: 0;
    padding-bottom: 0;
    height: 40px;
    margin-bottom: .2rem;
}

@mixin disabled-input {
    background-color: #fff;
    border: none !important;
}