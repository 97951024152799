.factsheet-details {
    .ul-facts {
        .badge {
            margin-right: 0.7rem;
            width: 0.8rem;
            font-size: 100%;
            padding: 0;
            margin-top: -0.1rem;
            background-color: transparent;
        }
        .title {
            display: block;
        }
        &>li:not(:last-child) {
            margin-bottom: 1rem
        }
        .fact-content {
            margin-left: 1.5rem
        }
    }
}

.factsheet-card {
    .card-body {
        padding: 15px;
    }
}